import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "q-pa-sm ecran-container" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_3 = {
  key: 0,
  class: "app__page__title__container"
}
const _hoisted_4 = {
  class: "app__page__title",
  style: {"white-space":"nowrap"}
}
const _hoisted_5 = { style: {"margin":"auto"} }
const _hoisted_6 = { style: {"margin-left":"auto","display":"flex","align-items":"center"} }
const _hoisted_7 = { class: "q-pa-xs col-xs-12 col-sm-6 col-md-4" }
const _hoisted_8 = { style: {"display":"flex","flex-direction":"column","margin-left":"1rem"} }
const _hoisted_9 = { class: "app__property--medium" }
const _hoisted_10 = { class: "app__property--medium" }
const _hoisted_11 = { class: "app__property--medium" }
const _hoisted_12 = { class: "q-pa-lg flex flex-center" }
const _hoisted_13 = {
  key: 0,
  class: "app__title--small",
  style: {"position":"absolute","left":"50%","margin-left":"-100px"}
}
const _hoisted_14 = {
  key: 1,
  class: "app__title--small",
  style: {"position":"absolute","left":"calc(50% - 100px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$q.platform.is.desktop)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "apartment",
                style: {"font-size":"2rem","top":"-3px"},
                color: "primary"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.companies')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          color: "blue",
          onClick: _ctx.syncronizeApollo,
          loading: _ctx.loadingApollo
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('message.manage_items_apollo')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_q_input, {
          "bottom-slots": "",
          debounce: "300",
          modelValue: _ctx.filterText,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterText) = $event)),
          dense: _ctx.dense
        }, {
          append: _withCtx(() => [
            (_ctx.filterText !== '')
              ? (_openBlock(), _createBlock(_component_q_icon, {
                  key: 0,
                  name: "close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterText = '')),
                  class: "cursor-pointer"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_icon, { name: "search" })
          ]),
          _: 1
        }, 8, ["modelValue", "dense"]),
        _createVNode(_component_q_btn, {
          style: {"margin-left":"1rem"},
          padding: "xs",
          color: "blue",
          icon: "add",
          onClick: _ctx.openFormAddNewCompany
        }, null, 8, ["onClick"])
      ])
    ]),
    (_ctx.$q.platform.is.mobile)
      ? (_openBlock(), _createBlock(_component_q_table, {
          key: 0,
          grid: "",
          "rows-per-page-options": [10, 20],
          rows: _ctx.companies,
          columns: _ctx.columns,
          "visible-columns": _ctx.visibleColumns,
          "row-key": "appid",
          loading: _ctx.loadingData,
          pagination: _ctx.myPagination,
          "no-data-label": _ctx.$t('message.no_data')
        }, {
          item: _withCtx((props) => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { style: {"display":"flex"} }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('message.fiscal_code')) + ": " + _toDisplayString(props.row.cif), 1),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(props.row.denumire), 1),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(props.row.sales_team), 1),
                        _createVNode(_component_q_checkbox, {
                          modelValue: props.row.portal_status,
                          "onUpdate:modelValue": [($event: any) => ((props.row.portal_status) = $event), ($event: any) => (_ctx.changeStatus(props.row))],
                          label: _ctx.$q.platform.is.mobile? _ctx.$t('message.visible') :'',
                          "true-value": "y",
                          "false-value": "n"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                      ]),
                      _createVNode(_component_q_icon, {
                        name: "manage_accounts",
                        class: "text-blue cursor-pointer",
                        style: {"font-size":"1.5rem","margin-left":"auto"},
                        onClick: ($event: any) => (_ctx.openUserAdminForCompany(props.row.appid))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _: 1
        }, 8, ["rows", "columns", "visible-columns", "loading", "pagination", "no-data-label"]))
      : (_openBlock(), _createBlock(_component_q_table, {
          key: 1,
          dense: "",
          flat: "",
          "rows-per-page-options": [0],
          "hide-bottom": "",
          rows: _ctx.companies,
          columns: _ctx.columns,
          "visible-columns": _ctx.visibleColumns,
          "row-key": "appid",
          loading: _ctx.loadingData,
          "no-data-label": _ctx.$t('message.no_data')
        }, {
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "cif",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "manage_accounts",
                      class: "text-blue cursor-pointer",
                      style: {"font-size":"1.5rem"},
                      onClick: ($event: any) => (_ctx.openUserAdminForCompany(props.row.appid))
                    }, null, 8, ["onClick"]),
                    _createTextVNode(" " + _toDisplayString(props.row.cif), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "denumire",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.denumire), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "sales_team",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.sales_team), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "status",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_checkbox, {
                      modelValue: props.row.portal_status,
                      "onUpdate:modelValue": [($event: any) => ((props.row.portal_status) = $event), ($event: any) => (_ctx.changeStatus(props.row))],
                      label: _ctx.$q.platform.is.mobile? _ctx.$t('message.visible') :'',
                      "true-value": "a",
                      "false-value": "i"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "edit",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "edit",
                      class: "cursor-pointer",
                      style: {"font-size":"1.5rem"},
                      onClick: ($event: any) => (_ctx.openFormEditCompany(props.row.cif))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, {
                          anchor: "top middle",
                          self: "bottom middle",
                          offset: [10, 10],
                          "transition-show": "scale",
                          "transition-hide": "scale"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('message.edit_company')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_q_icon, {
                      name: "delete",
                      class: "cursor-pointer",
                      style: {"font-size":"1.5rem","color":"red"},
                      onClick: ($event: any) => (_ctx.askDeleteCompany(props.row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, {
                          anchor: "top middle",
                          self: "bottom middle",
                          offset: [10, 10],
                          "transition-show": "scale",
                          "transition-hide": "scale"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('message.delete_company')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "visible-columns", "loading", "no-data-label"])),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_q_pagination, {
        modelValue: _ctx.currentPage,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
        max: _ctx.totalNumberOfPages,
        input: ""
      }, null, 8, ["modelValue", "max"])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.dialogUser,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogUser) = $event)),
      persistent: "",
      maximized: true,
      "transition-show": _ctx.dialogTransitionShow,
      "transition-hide": _ctx.dialogTransitionHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { class: "bg-white" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_bar, null, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_q_btn, {
                  dense: "",
                  flat: "",
                  color: "black",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "arrow_back_ios",
                      style: {"font-weight":"bold"}
                    })
                  ]),
                  _: 1
                }, 512), [
                  [_directive_close_popup]
                ]),
                (_ctx.$q.platform.is.desktop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.titleComponent), 1))
                  : _createCommentVNode("", true),
                (_ctx.$q.platform.is.mobile)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.titleComponent), 1))
                  : _createCommentVNode("", true),
                (_ctx.$q.platform.is.desktop)
                  ? (_openBlock(), _createBlock(_component_q_space, {
                      key: 2,
                      dense: ""
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dynamicComponent), {
                  userappid: _ctx.selectedUser.appid,
                  fnOnSaveData: _ctx.fnOnSaveData,
                  cif: _ctx.selectedCif
                }, null, 8, ["userappid", "fnOnSaveData", "cif"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "transition-show", "transition-hide"])
  ]))
}