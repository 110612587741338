import {Component, Prop, Watch} from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component'
import extend from 'quasar/src/utils/extend.js';
import user from '@/store/user';
import eventbus from '@/store/eventbus';
import {URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {TUser} from '@/types/TUser';
import {TUserForAdmin} from '@/types/TUserForAdmin';
import {TAdminCompany} from '@/types/TAdminCompany';
import {ServiceAdminUsers} from '@/services/ServiceAdminUsers';
import {ServiceAdminCompanies} from '@/services/ServiceAdminCompanies';
import {CONFIG_ENV} from '@/config';
import UserProfile from '@/components/Admin/UserProfile/UserProfile.vue'
import EditNewUser from '@/components/Admin/EditNewUser/EditNewUser.vue'
import EditNewCompany from '@/components/Admin/EditNewCompany/EditNewCompany.vue';
import { TSalesTeams } from '@/types/TSalesTeams';
import nomenclatoare from '@/store/nomenclatoare';
import { TCompany } from '@/types/TCompany';


@Options({
    name: "AdminCompanies",
    components: {UserProfile, EditNewUser, EditNewCompany}
})
export default class AdminCompanies extends Vue {
    @Prop({ default: '0' }) public readonly userid!: string;
    public companies:TAdminCompany[] = [];
    public loadingData = true;
    public isAdminUsersActivated=false;
    public dialogUser = false;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public urlUserImgProfileByAppid = CONFIG_ENV.URL_ADMIN.users+'/img_user_profile_jpg_by_user_appid'
    public filterText = '';
    public titleComponent='';
    declare public $refs: any;
    public currentPage=1;
    public dynamicComponent='';
    public totalNumberOfPages=2;
    public selectedUser:TUserForAdmin={
        appid: '',
        userid: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNr: '',
        isDisabled: '',
        companyName: '',
        userToken: '',
        urlUserImgProfileByAppid: '',
        hasAvatar: '',
        tip: 'client',
        agentId: '',
        id_team: '',
        agent_name: ''
    }
    public userStore = getModule(user);
    public EventBusStore = getModule(eventbus);
    public storeNomenclatoare = getModule(nomenclatoare);
    public myPagination:any={rowsPerPage:10}
    public visibleColumns: string[] = [  'cif', 'sales_team', 'denumire', 'status', 'edit'];
    public  columns: any = [];
    public selectedCif = '';
    public loadingApollo = false;

    public get user(): TUser {
        return this.userStore.user;
    }

    public get salesTeams(): TSalesTeams[] {
        return this.storeNomenclatoare.nomSalesTeams;
    }

    public getTeam(id_team: string){
        const team = this.salesTeams.find((team) => team.id_team == id_team);
        console.log(this.salesTeams);
        if(team != undefined) {
            return team.team_name;
        }
        return '';
    }

    public resetPassword(){
        ServiceAdminUsers.resetPassword('abc')
    }

    public openUserAdminForCompany(companyAppid:string){
        //this.selectedUser=puser
        // extend(true, this.selectedUser,  puser);
        console.log('companyAppid=%o',companyAppid)
        this.$router.push({ name: 'AdminUsers', params: { companyAppid:companyAppid } });
    }

    public fnOnSaveData(userappid:string){
        console.log('fnOnSaveData with userid=%o',userappid);
        this.dialogUser=false;
        this.selectedCif = '';
        // this.openUserDetails(userappid);
        this.getPageCompanies(this.currentPage);
    }

    public openFormAddNewUser(){
        this.selectedUser.appid='0';
        this.titleComponent='Creare cont nou de utilizator';
        this.dynamicComponent='EditNewUser';
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogUser=true;
    }

    public openFormAddNewCompany(){
        
        this.selectedUser.appid='0';
        this.titleComponent=this.$t('message.create_new_company');
        this.dynamicComponent='EditNewCompany';
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogUser=true;
    }

    public openFormEditCompany(cif: string){
        
        this.selectedUser.appid='0';
        this.titleComponent=this.$t('message.edit_company');
        this.dynamicComponent='EditNewCompany';
        this.selectedCif = cif;
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogUser=true;
    }

    public getPageCompanies(pageNumber:number){
        const vueInst = this;
        vueInst.loadingData=true;
        vueInst.companies=[];
        ServiceAdminCompanies.getCompanies(pageNumber,vueInst.filterText).then(response=>{
            vueInst.loadingData=false;
            if(response.status=='success'){
                vueInst.totalNumberOfPages=response.totalPages;
                vueInst.companies=response.page.items.map(company=> {return {
                    appid: company.appid,
                    cif: company.cif,
                    denumire: company.denumire,
                    rg_jfc: company.rg_jfc,
                    rg_nr: company.rg_nr,
                    rg_an: company.rg_an,
                    pf_pj: company.pf_pj,
                    id_country: company.id_country,
                    sales_team: vueInst.getTeam(company.sales_team),
                    portal_status: company.portal_status,
                    msz: company.msz
                }});
            }
        })
    }

    public activated(){
        this.isAdminUsersActivated=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar(this.$t('message.companies'));
        }
    }

    public syncronizeApollo() {
        const vueInst = this;
        vueInst.loadingApollo = true;
        ServiceAdminCompanies.getCompaniesApollo().then(() => {
            vueInst.loadingApollo = false;
            vueInst.getPageCompanies(1);
        }).catch(()=>{
            vueInst.loadingApollo = false;
        });
    }

    public askDeleteCompany(company: TAdminCompany){
        const vueInst = this;
        vueInst.$q.dialog({
            title: this.$t('message.delete_company'),
            message: `${this.$t('message.delete_company_ask')} ${company.denumire}?`,
            ok: {
                flat:true,
                label: this.$t('message.delete'),
            },
            cancel: {
                label: this.$t('message.drop'),
                flat:true
            },
            persistent: true
        }).onOk(() => {
            ServiceAdminCompanies.deleteCompany(company.appid).then(response=>{
                if(response.status == 'success') {
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        icon: 'positive',
                        position: 'top',
                        timeout: 2000,
                        message: this.$t('message.company_deleted')
                    });
                    vueInst.getPageCompanies(1);
                } else {
                    vueInst.$q.notify({
                        color: 'red',
                        textColor: 'white',
                        icon: 'negative',
                        position: 'top',
                        timeout: 2000,
                        message: this.$t('message.company_not_found')
                    });
                }
            })
        })
    }

    @Watch('EventBusStore.event.eventId')
    onEventIdChanged() {
        const vueInst=this;
        if(vueInst.EventBusStore.event.name=='eventClickAddBtn'){
            if(vueInst.isAdminUsersActivated){
                vueInst.openFormAddNewUser();
            }
        }
    }


    @Watch('currentPage')
    onCurrentPageChanged() {
        const vueInst=this;
        vueInst.getPageCompanies(vueInst.currentPage);
    }

    @Watch('filterText')
    onFilterTextChanged() {
        const vueInst=this;
        vueInst.getPageCompanies(vueInst.currentPage);
    }

    public created(): void {
        const vueInst = this;
        vueInst.currentPage=1;
        vueInst.columns = [
            { name: 'appid', label: '', field: 'appid', align: 'left'},
            { name: 'cif', label: this.$t('message.fiscal_code'), field: 'cif', align: 'left', sortable: true},
            { name: 'denumire', label: this.$t('message.name2'), field: 'denumire', align: 'left', sortable: true },
            { name: 'sales_team', label: this.$t('message.sales_team_id'), field: 'sales_team', align: 'left', sortable: true },
            { name: 'status', label: this.$t('message.status'), field: 'status', align: 'left'},
            { name: 'edit', label: '', field: 'edit', align: 'left'}

        ];
    
        vueInst.getPageCompanies(vueInst.currentPage);

    }

    changeStatus(company: TAdminCompany){
        const vueInst=this;
        ServiceAdminCompanies.changeStatusActivInactiv(company.cif,company.portal_status).then(response=>{
            if(response.status == 'success'){
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    icon: 'positive',
                    position: 'top',
                    timeout: 2000,
                    message: this.$t('message.visibility_change_success')
                })

                if(company.portal_status == 'a') {
                    ServiceAdminCompanies.getCompanyApollo(company.cif);
                }
            }
            
        })
    }

    public deactivated() {
        this.isAdminUsersActivated = false;
    }

}
